<template>
    <div class="mt-second-hero">
        <section>
            <div class="container">
                <h2 class="text-dark fw-ekstra-bold">
                    News
                </h2>
                <div class="row">
                    <div class="col-md-5 mt-4">
                        <img src="../assets/news-1.jpg" width="100%" class="small-radius-image" alt="">
                        <div class="mt-3 text-gray fw-medium">
                            12 Juli 2022
                        </div>
                        <div class="mt-4 size-18 fw-ekstra-bold text-dark">
                            Keputusan IDI mengenai  pencegahan kajian virus Covid -19 varian terbaru
                        </div>
                        <div class="mt-3 text-dark fw-medium">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                        </div>
                    </div>
                    <div class="col-md-7 mt-4">
                        <div class="box no-shadow border-gray">
                            <div class="row">
                                <div class="col-md-4">
                                    <img src="../assets/news-2.jpg" width="100%" class="small-radius-image" alt="">
                                </div>
                                <div class="col-md-8">
                                    <div class="fw-ekstra-bold size-16 text-dark">
                                        Keputusan IDI mengenai  pencegahan kajian virus Covid -19 varian terbaru
                                    </div>
                                    <div class="mt-2 text-gray fw-medium">
                                        12 Juli 2022
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="box no-shadow border-gray mt-3">
                            <div class="row">
                                <div class="col-md-4">
                                    <img src="../assets/news-3.jpg" width="100%" class="small-radius-image" alt="">
                                </div>
                                <div class="col-md-8">
                                    <div class="fw-ekstra-bold size-16 text-dark">
                                        Keputusan IDI mengenai  pencegahan kajian virus Covid -19 varian terbaru
                                    </div>
                                    <div class="mt-2 text-gray fw-medium">
                                        12 Juli 2022
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="box no-shadow border-gray mt-3">
                            <div class="row">
                                <div class="col-md-4">
                                    <img src="../assets/news-4.jpg" width="100%" class="small-radius-image" alt="">
                                </div>
                                <div class="col-md-8">
                                    <div class="fw-ekstra-bold size-16 text-dark">
                                        Keputusan IDI mengenai  pencegahan kajian virus Covid -19 varian terbaru
                                    </div>
                                    <div class="mt-2 text-gray fw-medium">
                                        12 Juli 2022
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="mt-section">
            <div class="container">
                <h2 class="text-dark fw-ekstra-bold">
                    Article 
                </h2>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <div class="box no-shadow border-gray">
                            <div class="row">
                                <div class="col-md-4">
                                    <img src="../assets/news-2.jpg" width="100%" class="small-radius-image" alt="">
                                </div>
                                <div class="col-md-8">
                                    <div class="fw-ekstra-bold size-16 text-dark">
                                        Keputusan IDI mengenai  pencegahan kajian virus Covid -19 varian terbaru
                                    </div>
                                    <div class="mt-2 text-gray fw-medium">
                                        12 Juli 2022
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-4">
                        <div class="box no-shadow border-gray">
                            <div class="row">
                                <div class="col-md-4">
                                    <img src="../assets/news-3.jpg" width="100%" class="small-radius-image" alt="">
                                </div>
                                <div class="col-md-8">
                                    <div class="fw-ekstra-bold size-16 text-dark">
                                        Keputusan IDI mengenai  pencegahan kajian virus Covid -19 varian terbaru
                                    </div>
                                    <div class="mt-2 text-gray fw-medium">
                                        12 Juli 2022
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-4">
                        <div class="box no-shadow border-gray">
                            <div class="row">
                                <div class="col-md-4">
                                    <img src="../assets/news-4.jpg" width="100%" class="small-radius-image" alt="">
                                </div>
                                <div class="col-md-8">
                                    <div class="fw-ekstra-bold size-16 text-dark">
                                        Keputusan IDI mengenai  pencegahan kajian virus
                                    </div>
                                    <div class="mt-2 text-gray fw-medium">
                                        12 Juli 2022
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-4">
                        <div class="box no-shadow border-gray">
                            <div class="row">
                                <div class="col-md-4">
                                    <img src="../assets/news-1.jpg" width="100%" class="small-radius-image" alt="">
                                </div>
                                <div class="col-md-8">
                                    <div class="fw-ekstra-bold size-16 text-dark">
                                        Keputusan IDI mengenai pencegahan kajian virus Covid -19 varian terbaru
                                    </div>
                                    <div class="mt-2 text-gray fw-medium">
                                        12 Juli 2022
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    components: {
    },
    name: 'Home',
    data() {
        return{
        }
    },
    created() {
    },
    methods: {
    }
}
</script>
